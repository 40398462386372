import { Box, Flex } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import Logo from './logo.svg'
type Props = { children: ReactNode }

export const BottomBar: FC<Props> = ({ children }) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      width="100%"
      borderWidth=" 2px, 2px, 0px, 2px"
      borderStyle="solid"
      borderColor="white"
      height="64px"
      borderRadius="16px 16px 0 0"
      backdropFilter="blur(20px)"
      dropShadow={`box-shadow: 0px 5px 10px 0px #DBACF51A;
box-shadow: 0px 19px 19px 0px #DBACF517;
box-shadow: 0px 42px 25px 0px #DBACF50D;
box-shadow: 0px 75px 30px 0px #DBACF503;
box-shadow: 0px 118px 33px 0px #DBACF500;`}
      background="linear-gradient(114.14deg, rgba(255, 255, 255, 0.8) 2.72%, rgba(255, 255, 255, 0.6) 100.25%);"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        px="4"
      >
        <Logo />
        {children}
      </Flex>
    </Box>
  )
}
