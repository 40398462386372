import { Flex, Text } from '@chakra-ui/react'
import { useLingui } from '@lingui/react'
import type { Conversation } from 'components/api/useConversations'
import type { FC } from 'react'
import SingleConversation from './SingleConversation'

type Props = { date: string; conversations: Conversation[] }

const Conversations: FC<Props> = ({ conversations, date }) => {
  const { i18n } = useLingui()

  return (
    <Flex gap="4" flexDir="column">
      {conversations
        .reverse()
        .map(
          (c) =>
            c.aiInsights.customSummary && (
              <SingleConversation
                agent={c.agent}
                dateTime={c.dateTime}
                duration={c.duration}
                subject={c.subject}
                customSummary={c.aiInsights.customSummary}
                conversationUid={c.conversationUid}
                key={c.dateTime}
                team={c.team}
                keyTopic={c.aiInsights.keyTopic}
                subKeyTopic={c.aiInsights.keySubTopic}
                rootCause={c.aiInsights.rootCause}
              />
            ),
        )}
      <Flex py="5" alignItems="center" justifyContent="center">
        <Text px="4" whiteSpace="nowrap">
          {i18n.date(date, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Conversations
