import { Button, Flex, SlideFade } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { MagicBox } from '../MagicBox'
import { DynamicTextArea } from './DynamicTextArea'

type Props = {
  summary: string | undefined
  onCancel: () => void
}

export const LiveSummary: React.FC<Props> = ({ summary, onCancel }) => {
  const [state, setState] = useState(summary)
  const [isLoading, setIsLoading] = useState(false)

  if (state === undefined && summary !== undefined) {
    setState(summary)
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && summary) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [summary])

  const handleLoad = () => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 1000)
  }

  return (
    <>
      <SlideFade in={true}>
        <MagicBox p="1" width="800px">
          <DynamicTextArea
            isDisabled={isLoading}
            border="none"
            value={state}
            onChange={(e) => setState(e.currentTarget.value)}
            focusBorderColor="none"
            resize="none"
          />
        </MagicBox>

        <Flex mt="2" justifyContent="space-between">
          <Flex />
          <Flex>
            <Button
              variant="ghost"
              size="sm"
              colorScheme="primary"
              isDisabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isLoading}
              onClick={handleLoad}
            >
              Save Summary
            </Button>
          </Flex>
        </Flex>
      </SlideFade>
      <div ref={ref} />
    </>
  )
}
