import { Textarea, TextareaProps } from '@chakra-ui/react'
import { type FC, useEffect, useRef } from 'react'

const textAreaId = crypto.randomUUID()

export const DynamicTextArea: FC<Omit<TextareaProps, 'height' | 'h'>> = ({
  value,
  onChange,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px'
      const scrollHeight = textAreaRef.current.scrollHeight
      textAreaRef.current.style.height = `${scrollHeight}px`
    }
    if (document.activeElement?.id === textAreaId) {
      textAreaRef.current?.scrollIntoView({ behavior: 'instant' })
    }
  }, [value])

  return (
    <Textarea
      id={textAreaId}
      ref={textAreaRef}
      value={value}
      onChange={(e) => onChange?.(e)}
      {...props}
    />
  )
}
