import { connect } from '@puzzel/widget-api-lib'
import React, { useEffect, useState } from 'react'

type SystemCall = {
  system_call_progress:
    | 'CONNECTED'
    | 'HANGUP'
    | 'ALERTING'
    | 'NOANSWER'
    | 'ABORTED'
    | 'SPLIT'
    | 'BUSY'
  system_caller_ano: string
  language: string
  callerStatus: 'New Call'
  current_state: string
  system_caller_transfered: boolean
  __CLIENT_IS_OUTBOUND__: boolean
  ciq_destination: string
  system_session_id: string
}

type PuzzelCall = {
  sessionId: string
  caller: string
}

export const PuzzelEvents: React.FC = () => {
  const [data, setData] = useState<{ event: string; data: unknown }[]>([])

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on init
  useEffect(() => {
    const doStuff = async (): Promise<void> => {
      try {
        const api = await connect()

        api.on('SYSTEM_REQUEST_IS_CONNECTED', () => {
          // biome-ignore lint/suspicious/noConsoleLog: <explanation>
          console.log('SYSTEM_REQUEST_IS_CONNECTED')
        })

        api.on('SYSTEM_INCOMING_CALL', (data: { call: PuzzelCall }) => {
          setData((d) => [...d, { event: 'SYSTEM_INCOMING_CALL', data }])

          // biome-ignore lint/suspicious/noConsoleLog: <explanation>
          console.log('SYSTEM_INCOMING_CALL:', data)
        })

        api.on('SYSTEM_CALL_TRIGGER', (vars: SystemCall) => {
          setData((d) => [...d, { event: 'SYSTEM_CALL_TRIGGER', data: vars }])
          // biome-ignore lint/suspicious/noConsoleLog: <explanation>
          console.log('SYSTEM_CALL_TRIGGER:', vars)
        })

        api.on(
          'SYSTEM_CALL_ASR_TRANSCRIPTION_TRIGGER',
          (data: unknown): void => {
            setData((d) => [
              ...d,
              { event: 'SYSTEM_CALL_ASR_TRANSCRIPTION_TRIGGER', data },
            ])

            // biome-ignore lint/suspicious/noConsoleLog: <explanation>
            console.log('SYSTEM_CALL_ASR_TRANSCRIPTION_TRIGGER :', data)
          },
        )
        api.on('SYSTEM_CALL_ASR_TRANSCRIPTION', (data: unknown): void => {
          setData((d) => [
            ...d,
            { event: 'SYSTEM_CALL_ASR_TRANSCRIPTION', data },
          ])
          // biome-ignore lint/suspicious/noConsoleLog: <explanation>
          console.log('SYSTEM_CALL_ASR_TRANSCRIPTION :', data)
        })

        await api.ready()
      } catch (error) {
        // biome-ignore lint/suspicious/noConsoleLog: <explanation>
        console.log('error :', error)
      }
    }

    doStuff()
  }, [])

  return <div>{JSON.stringify(data, undefined, '   ')}</div>
}
