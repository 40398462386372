import { Box, Flex, Text } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import BigFlower from './BigFlower.svg'
import Flower from './Flower.svg'
import Guirlande from './Guirlande.svg'
import PuzzelLogo from './PuzzelLogo.svg'

export const InitialNotConnected: React.FC = () => {
  return (
    <Box
      height={'100vh'}
      bg="white"
      width={'100%'}
      background="linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), radial-gradient(88.67% 177.33% at 103.33% 100%, #33C4B3 0%, rgba(51, 196, 179, 0) 100%), linear-gradient(360deg, rgba(51, 196, 179, 0.25) 0%, rgba(51, 196, 179, 0.5) 66%)"
    >
      <Box position="absolute" top={0} left={0}>
        <Guirlande />
      </Box>
      <Flex
        alignItems="center"
        justify="center"
        width="100%"
        flexDirection="column"
      >
        <Text mt="30%" fontSize="2xl" fontWeight="extrabold">
          <Trans>Start a conversation</Trans>
        </Text>
        <Text fontSize="lg" color="gray.600" mb="50px">
          ... and get a pre call summary, summaries of previous conversations
          and recommendations.
        </Text>

        <PuzzelLogo />
      </Flex>

      <Box
        position="absolute"
        bottom={0}
        width="100%"
        height="300px"
        bg="white"
        css={css`
  --c: 100px;
  aspect-ratio: 3/2;
  mask: radial-gradient(60% var(--c) at top,#0000 calc(100% - 1px),#000);
            `}
      />
      <Box position="absolute" bottom={0} left={0}>
        <BigFlower />
      </Box>
      <Box position="absolute" bottom={0} right={0}>
        <Flower />
      </Box>
    </Box>
  )
}
