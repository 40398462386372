import CircularLoader from '@capturi/assets/images/CircularLoader.svg'
import { MagicBox, MagicBoxHeader } from '@capturi/ui-components'
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import { usePostHog } from 'posthog-js/react'
import React, { useState } from 'react'
import { MdOutlineThumbDown, MdOutlineThumbUp } from 'react-icons/md'
import { TranscriptionSummary } from './TranscriptionSummary'
import { useLiveTranscriptionSummary } from './api/useLiveTranscriptionSummary'
import KeyTopicTitle from './styles/KeyTopicTitle'

type Props = {
  apiToken: string
  sessionId: string | null
  subTopic: string | null
  topic: string | null
  summary: string | null
  isLoading: boolean
  error: Error | null
  date: Date
}

const formatTime = format('HH:mm')

const VoiceBot: React.FC<Props> = ({
  error,
  isLoading,
  subTopic,
  summary,
  topic,
  date,
  apiToken,
  sessionId,
}) => {
  const toast = useToast()
  const [isReviewed, setIsReviewed] = useState(false)
  const posthog = usePostHog()
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const transcriptionFeatureEnabled =
    sessionId && params.get('enableTranscriptionSummary') === 'true'
  const {
    data: transcriptionSummary,
    isPending: isSummaryLoading,
    isSuccess: isSummarySuccess,
    error: summaryError,
    mutate: fetchTranscriptionSummary,
  } = useLiveTranscriptionSummary()

  if (isLoading)
    return (
      <Flex justifyContent="center">
        <CircularLoader width="90px" height="90px" />
      </Flex>
    )
  if (error) return <Text>{error.message}</Text>

  return (
    <MagicBox>
      <MagicBoxHeader flexDir="row" justifyContent="space-between">
        <KeyTopicTitle keyTopic={topic} subKeyTopic={subTopic} />
        <HStack>
          {transcriptionFeatureEnabled &&
            !isSummarySuccess &&
            !isSummaryLoading && (
              <Button
                onClick={() =>
                  fetchTranscriptionSummary({ apiToken, sessionId })
                }
              >
                {t`Summarize`}
              </Button>
            )}
          <Text>{formatTime(date)}</Text>
        </HStack>
      </MagicBoxHeader>
      <VStack alignItems="stretch" pl="4" pr="3">
        {transcriptionFeatureEnabled &&
          (isSummaryLoading || isSummarySuccess || summaryError) && (
            <TranscriptionSummary
              transcriptionSummary={transcriptionSummary}
              isSummaryLoading={isSummaryLoading}
              summaryError={summaryError}
            />
          )}
        <HStack justify="space-between" py="3">
          <Text whiteSpace="break-spaces" pr="3">
            {summary || t`No details available`}
          </Text>
          {!isReviewed && summary && (
            <HStack gap="1">
              <IconButton
                aria-label="Thumbs up"
                icon={<MdOutlineThumbUp />}
                size="xs"
                variant="ghost"
                color="gray.500"
                onClick={(e) => {
                  e.stopPropagation()
                  posthog?.capture('voiceBot_ThumbsUp', {
                    summary: summary,
                    keyTopic: topic,
                    subTopic: subTopic,
                    date: date,
                  })
                  setIsReviewed(true)
                  toast({
                    title: t`Thank you for the feedback`,
                    description: t`You are helping us to improve future summaries`,
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                  })
                }}
              />
              <IconButton
                aria-label="Thumbs down"
                fontSize="8px"
                icon={<MdOutlineThumbDown />}
                size="xs"
                variant="ghost"
                color="gray.500"
                onClick={(e) => {
                  e.stopPropagation()
                  posthog?.capture('voiceBot_ThumbsDown', {
                    summary: summary,
                    keyTopic: topic,
                    subTopic: subTopic,
                    date: date,
                  })
                  setIsReviewed(true)
                  toast({
                    title: t`Thank you for the feedback`,
                    description: t`You are helping us to improve future summaries`,
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                  })
                }}
              />
            </HStack>
          )}
        </HStack>
      </VStack>
    </MagicBox>
  )
}

export default VoiceBot
