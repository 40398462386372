import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    primary: {
      '50': '#efebf1',
      '100': '#cec1d4',
      '200': '#b7a3c0',
      '300': '#967aa3',
      '400': '#816091',
      '500': '#623875',
      '600': '#59336a',
      '700': '#462853',
      '800': '#361f40',
      '900': '#291831',
    },
  },
})
