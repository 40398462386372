import { Box, type BoxProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'

// Defaults to 8px radius, but allows individual radii for each corner
export const MagicBox = React.forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    const defaultRadius = '8px' // '8px' in default Chakra theme
    const {
      borderTopLeftRadius = defaultRadius,
      borderTopRightRadius = defaultRadius,
      borderBottomLeftRadius = defaultRadius,
      borderBottomRightRadius = defaultRadius,
    } = props

    return (
      <Box
        maxW="800px"
        boxShadow="0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A"
        background="white"
        borderRadius={defaultRadius}
        position="relative"
        overflow="hidden"
        p="1px"
        w="100%"
        css={css`
          ::before {
            content: "";
            cursor: none;
            position: absolute;
            pointer-events: none;
            inset: 0;
            border-top-left-radius: ${borderTopLeftRadius};
            border-top-right-radius: ${borderTopRightRadius};
            border-bottom-left-radius: ${borderBottomLeftRadius};
            border-bottom-right-radius: ${borderBottomRightRadius};
            padding: 2px; 
            overflow: hidden;
            background:linear-gradient(
              180deg,
            rgba(255, 55, 91, 0.5) 0%, 
            rgba(255, 153, 3, 0.5) 49.48%, 
            rgba(51, 196, 179, 0.5)
            ); 
            -webkit-mask: 
              linear-gradient(#fff 0 0) content-box, 
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
                    mask-composite: exclude; 
          }
        `}
        {...props}
        ref={ref}
      />
    )
  },
)

export default MagicBox
