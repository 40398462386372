import { Box, Divider, Skeleton, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

type TranscriptionSummaryProps = {
  transcriptionSummary: string | undefined
  isSummaryLoading: boolean
  summaryError: Error | null
}

export const TranscriptionSummary: React.FC<TranscriptionSummaryProps> = ({
  transcriptionSummary,
  isSummaryLoading,
  summaryError,
}) => {
  return (
    <Box alignItems="stretch" gap="1" pt={1} w="100%">
      <Text color="gray.600" fontSize="sm" fontWeight="500">
        <Trans>Summary</Trans>
      </Text>
      {isSummaryLoading && (
        <VStack gap="1" alignItems="start">
          <Skeleton width="80%" height="4" />
          <Skeleton width="70%" height="4" />
          <Skeleton width="60%" height="4" />
        </VStack>
      )}
      {transcriptionSummary && (
        <Text whiteSpace="break-spaces">{transcriptionSummary}</Text>
      )}
      {summaryError && <Text>{summaryError.message}</Text>}
      <Divider mt={3} />
    </Box>
  )
}
