import { Box, Button, Center, Divider, Fade, Flex } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { type Filter, useConversations } from 'components/api/useConversations'
import { useLiveTranscriptionSummary } from 'components/api/useLiveTranscriptionSummary'
import HeaderDivider from 'components/styles/HeaderDivider'
import React, { useCallback, useEffect, useRef } from 'react'
import { BottomBar } from './BottomBar'
import { LiveSummary } from './LiveSummary'
import Loader from './Loader.svg'
import NotConnected from './NotConnected'
import { PreCall } from './PreCall'
import { PreviousConversations } from './PreviousConversations'

type Props = {
  filter?: Filter
  apiToken: string
  sessionId: string | null
}
const Widget: React.FC<Props> = ({ apiToken, sessionId, filter }) => {
  const url = new URL(window.location.toString())
  const params = url.searchParams
  //For a demo
  const customer = params.get('customer') || undefined
  const copilot = useLiveTranscriptionSummary()
  const prev = useConversations(
    apiToken,
    customer ? { customers: customer } : filter,
  )

  const ref = useRef<HTMLDivElement>(null)

  const handleScrolToBottom = useCallback(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleScrolToBottom()
  }, [copilot.data, prev.data])

  if (!prev.data) return <NotConnected />

  return (
    <>
      <Box height="100vh" overflow="scroll">
        <Flex mx="5" flexDir="column" justifyContent="end" alignItems="center">
          <PreviousConversations
            data={prev.data}
            // apiToken={apiToken}
            // filter={customer ? { customers: customer } : filter}
            // onDataLoaded={handleScrolToBottom}
          />

          <HeaderDivider> {t`Current Call`}</HeaderDivider>

          <Box minHeight="300px">
            <PreCall
              apiToken={apiToken}
              filter={filter}
              onDataLoaded={handleScrolToBottom}
            />

            <Fade in={!!copilot.data}>
              <Center height="30px" my="4">
                <Divider orientation="vertical" borderColor="gray.400" />
              </Center>
            </Fade>
            {copilot.isPending && (
              <Fade in>
                <Flex position="relative" justifyContent="center">
                  <Loader />
                </Flex>
              </Fade>
            )}
            {copilot.data && (
              <LiveSummary summary={copilot.data} onCancel={copilot.reset} />
            )}
          </Box>

          <Box height="70px" />
          <BottomBar>
            {!copilot.data && (
              <Button
                onClick={() =>
                  copilot.mutate({ apiToken, sessionId: sessionId || '' })
                }
                size="sm"
                colorScheme="primary"
                isLoading={copilot.isPending}
              >
                Summarize conversation
              </Button>
            )}
          </BottomBar>
        </Flex>
        <div ref={ref} />
      </Box>
    </>
  )
}

export default Widget
