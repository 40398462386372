import { Flex, SlideFade, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import type { Filter } from 'components/api/useConversations'
import { useVoiceBot } from 'components/api/useVoiceBot'
import KeyTopicTitle from 'components/styles/KeyTopicTitle'
import { MagicBox, MagicBoxHeader } from 'copilot/MagicBox'
import { type FC, useEffect, useRef } from 'react'
import VirtualAgentsIcon from './VirtualAgentsIcon.svg'
type Props = {
  filter?: Filter
  apiToken: string
  onDataLoaded: () => void
}
// const formatTime = format('HH:mm')

export const PreCall: FC<Props> = ({ apiToken, filter, onDataLoaded }) => {
  const { data } = useVoiceBot(apiToken, {
    phoneNumber: filter?.customers,
  })

  const ref = useRef<HTMLDivElement>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (data?.summary) {
      onDataLoaded()
    }
  }, [data?.summary])

  if (!data) return null

  const { subTopic, summary, topic } = data || {}

  return (
    <>
      <SlideFade in={data.status === 'OK'}>
        <Flex justifyContent="center">
          <MagicBox
            width="464px"
            backdropFilter="blur(64px)"
            background={` linear-gradient(114.14deg, rgba(255, 255, 255, 0.3) 2.72%, rgba(255, 255, 255, 0.05) 100.25%),
            radial-gradient(83% 166% at 110% 111.33%, rgba(96, 173, 231, 0.2) 0%, rgba(96, 173, 231, 0) 100%) `}
          >
            <MagicBoxHeader
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <KeyTopicTitle keyTopic={topic} subKeyTopic={subTopic} />
              <VirtualAgentsIcon />
            </MagicBoxHeader>
            <Text whiteSpace="break-spaces" p="3">
              {summary || t`No details available`}
            </Text>
          </MagicBox>
        </Flex>
      </SlideFade>
      <div ref={ref} />
    </>
  )
}
