import { type Conversation } from 'components/api/useConversations'
import { type FC } from 'react'
import Conversations from './Conversations'

type Props = {
  data: [string, Conversation[]][]
}

export const PreviousConversations: FC<Props> = ({ data }) => {
  // const { data, error } = useConversations(apiToken, filter)

  // useEffect(() => {
  //   if (data) onDataLoaded()
  // }, [data, onDataLoaded])

  // if (error) return <ErrorComponent error={error} />
  // if (!error && data?.length === 0) return <NotConnected />

  if (!data) return null

  //   return ([...data, ...data, ...data] || [])
  return (data || [])
    .toReversed()
    .map(([date, conversations]) => (
      <Conversations conversations={conversations} date={date} key={date} />
    ))
}
